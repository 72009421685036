import axios from "axios";
import { removeCharacter } from "utils/index";

// import { getMockData } from "./data";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Authorization: process.env.REACT_APP_API_AUTH,
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  },

});

export function getToken(values, captcha) {
  const { numero_os, cpf } = values;
  const [number, digit] = numero_os.split("-");
  return api.post("/orders/getinfosendsms", {
    customer: {
      doc: removeCharacter(cpf),
    },
    order: {
      number,
      digit,
    }
  }, {
    headers: {
      captcha,
    }
  });
}

export function validateToken(token, uuid) {
  return api.post("/token/check", {
    uuid,
    token: token.toUpperCase(),
  });
}

export function getOrder(uuid) {
  return api.get(`/orders/getinfo/${uuid}`);
}

export async function checkOrder(uuid, type) {

  const endpoint = "/orders/consult";
  const response = await api.post(endpoint, {
    uuid,
    type,
  });

  // if(response.data) response.data = getMockData(endpoint);

  return response;
}

export function getScheduling(uuid, type) {
  return api.post("/orders/scheduling", {
    uuid,
    type,
  });
}

export function doSchedule({ uuid, type, datetime, date, time, range }) {
  return api.post("/integration/reschedule", {
    uuid,
    type,
    datetime,

    date,
    time,
    range
  });
}

export function doCancel({ uuid }) {
  return api.post("/integration/cancel", {
    uuid,
  });
}

export function reportChat({ uuid, origin }) {
  return api.post("/sheets/chat", {
    uuid,
    origin,
  });
}
